<template>
  <div>
    <v-row wrap>
      <v-col cols="12">
        <v-autocomplete
          ref="drop"
          v-model="selectedData"
          :items="searchItems"
          item-value="id"
          dense
          filled
          outlined
          density="comfortable"
          placeholder="Select a release"
          item-text="version"
          append-icon="mdi-chevron-down"
          :clearable="multiple"
          :loading="loading"
          :multiple="multiple"
          :deletable-chips="multiple"
          class="tag-input"
          small-chips
          :disabled="disable"
          return-object
          :search-input.sync="search"
          hide-details
          @focusin.prevent="fetchData('no-default')"
          @change="$emit('change')"
          @blur="$emit('blur')"
        >
          <template slot="selection">
            <release-chip :release="selectedData"></release-chip>
          </template>
          <template #prepend-item>
            <v-row class="pl-2 pr-5" align="center" no-gutters>
              <v-col>
                <div class="caption">Version</div>
              </v-col>
              <v-col class="caption">
                <div>Version Code</div>
              </v-col>
              <v-col>
                <div class="caption text-right">Released</div>
              </v-col>
            </v-row>
          </template>
          <template slot="item" slot-scope="data">
            <v-row align="center" no-gutters>
              <v-col class="d-flex align-center justify-center">
                <v-list-item-title id="data-item">
                  <MaxText :text="data.item.version" :max="13" />
                </v-list-item-title>
              </v-col>
              <v-col class="caption">
                <MaxText :text="data.item.version_code" :max="13" />
              </v-col>
              <v-col>
                <div class="caption text-right">
                  <vue-hoverable-date
                    :date="data.item.created_at"
                  ></vue-hoverable-date>
                </div>
              </v-col>
            </v-row>
          </template>
          <template slot="no-data">
            <div>
              <div
                v-if="loading"
                class="d-flex align-center justify-center pa-4"
              >
                <ContentLoader size="25" />
              </div>
              <div v-else>
                <no-data :first-text="noDataMessage" />
              </div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import {
  GET_RELEASE_AUTOCOMPLETE,
  GET_RELEASE_INITIAL_LIST,
} from "@/store/releases/releases.module";

export default {
  props: {
    noChips: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    noDataMessage: {
      type: String,
      default: 'No releases'
    },
    value: {
      type: [Number, String, Array, Object],
      default: undefined,
    },
    default: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: [Array, Object, String],
      default() {
        return "";
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    firstLoad: {
      type: Boolean,
      default: false,
    },
    defaultRelease: {
      type: Object,
      default: null,
    },
    destination: {
      type: String,
      default: null
    },
    currentRelease: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectedData: "",
      search: "",
      searchItems: [],
      loading: false,
      newAppName: "",
      members: null,
      setMessage: false,
      openAppForm: false,
      invite: null,
      current: null,
    };
  },
  computed: {
    showItems() {
      if (this.selectedData && this.selectedData.length) {
        return [...this.searchItems, ...this.selectedData];
      } else if (
        typeof this.selectedData === "object" &&
        Object.keys(this.selectedData).length
      ) {
        return [...this.searchItems, this.selectedData];
      } else {
        return this.searchItems;
      }
    },
  },
  watch: {
    focus(val, oldVal) {
      if (!val && oldVal) {
        this.setMessage = false;
      } else {
        this.setMessage = val;
      }
    },
    // platform(val, oldVal) {
    //   this.fetchData(val, oldVal)
    // },
    search(val) {
      if (val && val.length > 0) {
        this.newAppName = val;
        clearTimeout(this._timerId);
        this.loading = true;
        this._timerId = setTimeout(() => {
          this.getSearchValue(val);
        }, 500);
      } else {
        this.searchItems = this.initialList;
      }
    },
    selectedData(val) {
      if (val && typeof val === "object" && val.id) {
        this.initialList = []
        this.initialList.push(val);
      }
      this.$emit("input", val ? val : {});
      this.search = "";
      this.$nextTick(() => {
        this.$emit("focus", false);
        this.setMessage = false;
      });
    },
    items(val) {
      this.searchItems = [...val];
    },
    initialList(val) {
      this.searchItems = [...val];
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    if(this.defaultRelease) {
      this.selectedData = this.defaultRelease
    }
  },
  beforeDestroy() {
    this.setReleaseInitialList([]);
  },
  methods: {
    ...mapMutations({
      setReleaseInitialList: "setReleaseInitialList",
    }),
    clearFocus() {
      this.setMessage = false;
      this.$emit("focus", false);
    },
    populateInitialData(val) {
      if (this.selectedData) return;
      if (val) {
        this.getSearchValue(val);
      } else {
        this.searchItems = [...this.initialList];
      }
      this.$emit("input", this.selectedData);
    },
    getSearchValue(val) {
      this.loading = true;
      this.$store
        .dispatch(GET_RELEASE_AUTOCOMPLETE, {
          app_id: this.$route.params.app_id,
          platform: this.platform,
          destination: this.destination,
          query: val,
        })
        .then((data) => {
          this.items = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage({ message: err.message });
        });
    },
    remove(item) {
      if (this.selectedData instanceof Object) {
        const index = this.selectedData.indexOf(item.id);
        if (index >= 0) this.selectedData.splice(index, 1);
      } else {
        this.selectedData = null;
      }
    },
    fetchData() {
      this.clearFocus();
      if (this.initialList && this.initialList.length) {
        return;
      }
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_RELEASE_INITIAL_LIST, {
          app_id: this.$route.params.app_id,
          platform: this.platform,
          destination: this.destination,
        })
        .then((data) => {
          this.initialList = data.data;
          this.searchItems = data.data;
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
