<template>
  <v-row>
    <v-overlay v-if="busy" :value="true" absolute>
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-col v-if="selectedFiles.length" cols="12">
      <v-row>
        <v-col
          v-for="(file, index) in selectedFiles"
          :key="index"
          cols="12"
          md="4"
        >
          <v-hover v-slot="{ hover }" close-delay="200">
            <v-card class="cardBackgroud" outlined>
              <v-row>
                <v-col cols="12" md="12">
                  <v-img
                    ref="attachment"
                    :src="file.thumb || file.src"
                    contain
                    height="200"
                    width="100%"
                  >
                    <span class="top float-right">
                      <v-icon
                        color="primary"
                        v-text="
                          file.mime === 'video/mp4'
                            ? 'mdi-message-video'
                            : 'mdi-image'
                        "
                      ></v-icon>
                    </span>
                  </v-img>
                  <v-overlay :value="hover" absolute color="grey" opacity="0.1">
                    <div class="center-el">
                      <v-btn
                        class="mr-1"
                        color="white"
                        fab
                        small
                        @click="openEditModal(file)"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        color="white"
                        fab
                        small
                        @click="openDeleteDialog(file)"
                      >
                        <v-icon color="primary">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-overlay>
                </v-col>
                <!--                <v-col class="mt-3" cols="7">-->

                <!--                <div class="darkGrey&#45;&#45;text bodyFont">-->
                <!--                  {{ file.name }}-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                  {{ formatBytes(file.size / 1000) }}-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                  {{ file.note }}-->
                <!--                </div>-->
                <!--                </v-col>-->
                <v-col v-if="attachmentErrors.length" cols="12">
                  <div v-if="attachmentErrors.length" class="px-2 error--text">
                    {{
                      attachmentErrors.find((x) => x.info.id == file.id).message
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          class="text-center drag-upload"
          cols="12"
          md="4"
          @dragenter.prevent=""
          @dragover.prevent=""
          @drop.prevent="processDraggedFile"
        >
          <div>
            <input
              v-show="false"
              :id="`file-upload`"
              :key="imageCropie"
              ref="inputField"
              :accept="'.jpg,.png,.mp4'"
              :disabled="busy"
              class="input-file"
              multiple
              type="file"
              @change="onFileSelect($event)"
            />
            <v-card class="center-el" height="200" outlined @click="pickFile">
              <v-row align="center" justify="center">
                <v-col class="" cols="auto">
                  <v-btn fab tile x-small>
                    <v-icon color="primary" v-text="'mdi-plus'"></v-icon>
                  </v-btn>
                </v-col>
                <v-col class="pl-0" cols="8">
                  <div class="grey-muted-text bodyFont pointer">
                    <div class="darkGrey--text">
                      Upload a file from your system
                      <span
                        class="primary--text text-decoration-underline cursor-pointer"
                        >Browser</span
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="!selectedFiles.length"
      class="drag-upload center-el pa-0 text-center"
      cols="12"
      @click="pickFile"
    >
      <v-card
        :color="$vuetify.theme.dark ? '' : '#f1f5fc'"
        :flat="!$vuetify.theme.dark"
        :outlined="$vuetify.theme.dark"
        class="pa-3"
        width="100%"
      >
        <div
          @dragenter.prevent=""
          @dragover.prevent=""
          @drop.prevent="processDraggedFile"
        >
          <input
            v-show="false"
            :id="`file-upload`"
            :key="imageCropie"
            ref="inputField"
            :accept="'.jpg,.png,.mp4'"
            :disabled="busy"
            class="input-file"
            multiple
            type="file"
            @change="onFileSelect($event)"
          />
          <div class="titleFont mb-4 primary--text">
            <v-icon color="primary" v-text="'mdi-paperclip'"></v-icon>
            No attachments
          </div>
          <div class="pointer">
            Drag and drop
            <div>
              or upload a file from your system
              <span
                class="primary--text text-decoration-underline cursor-pointer"
                >Browser</span
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
    <div id="canvas"></div>

    <Modal v-model="imageCropie" :fullscreen="$vuetify.breakpoint.smAndDown">
      <template #card>
        <v-card>
          <v-card-title>
            <div class="primary--text font17">Attachment</div>
            <v-spacer />
            <v-btn icon @click="cancelImageUpload">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!--            <v-btn-->
            <!--              v-if="-->
            <!--                !showCroppieArea && !['video/mp4', 'mp4'].includes(fileType)-->
            <!--              "-->
            <!--              @click="openCroppieArea()"-->
            <!--              icon-->
            <!--            >-->
            <!--              <v-icon color="primary" v-text="'mdi-crop'"></v-icon>-->
            <!--            </v-btn>-->
          </v-card-title>
          <v-card-text v-if="imageCropie" class="">
            <v-row align="center">
              <v-col cols="12">
                <video
                  v-if="previewFile && ['video/mp4', 'mp4'].includes(fileType)"
                  id="my-video"
                  ref="attachment"
                  controls
                  height="200"
                  width="100%"
                >
                  <source :src="previewFile" type="video/mp4" />
                </video>
                <div v-else>
                  <vue-croppie
                    v-if="!showImgCroppedImage && showCroppieArea"
                    ref="croppieRef"
                    :boundary="getViewPort"
                    :enableResize="true"
                    :viewport="getViewPort"
                    enforceBoundary
                    @result="croppedResults"
                  ></vue-croppie>
                  <v-img
                    v-if="showImgCroppedImage || !showCroppieArea"
                    ref="attachment"
                    :src="previewFile"
                    contain
                    height="282"
                    width="100%"
                  ></v-img>
                  <div
                    v-if="!showImgCroppedImage && showCroppieArea"
                    class="text-center"
                  >
                    <v-btn
                      :outlined="$vuetify.theme.dark"
                      class="text-transform-none"
                      color="primary"
                      @click="cropImage"
                    >
                      Crop
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="attachmentNote"
                  v-validate="'max:100'"
                  counter="100"
                  filled
                  outlined
                  placeholder="Notes"
                  rows="2"
                  v-bind="veeValidate('Note', '')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mb-3">
            <v-spacer></v-spacer>
            <!--            <v-btn-->
            <!--              class="text-transform-none"-->
            <!--              text:color="$vuetify.theme.dark ? 'primary' : ''"-->
            <!--              @click="cancelImageUpload"-->
            <!--            >-->
            <!--              Cancel-->
            <!--            </v-btn>-->
            <v-btn
              :disabled="showCroppieArea"
              :loading="loading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              @click="selectFileForUpload"
            >
              {{ actionFile?.id ? "Update" : "Add" }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="removeModal" no-py>
      <template #card>
        <v-card>
          <v-card-title class="font-weight-bold">Remove app</v-card-title>
          <v-divider />
          <v-card-text class="my-4 message">
            <p>Are you sure you want to remove this file?</p>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-3">
            <v-spacer />
            <v-btn
              id="leaveAppCancelBtn"
              class="text-transform-none"
              color="primary"
              text
              @click="removeModal = false"
              >No
            </v-btn>
            <v-btn
              id="leaveAppConfirmBtn"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              @click="removeSelectedFile"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import Vue from "vue";
import VueCroppie from "vue-croppie";
import veeValidate from "@/mixins/veeValidate";
import { formatBytes } from "@/core/services/helper.service";
import "croppie/croppie.css";

Vue.use(VueCroppie);
export default {
  name: "new-file-upload",
  mixins: [veeValidate],
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    progressList: {
      type: Array,
      default() {
        return [];
      },
    },
    attachmentErrors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      imageCropie: false,
      fileName: "",
      fileType: "",
      busy: false,
      showCroppieArea: false,
      formatBytes,
      removeModal: false,
      fileSize: "",
      actionFile: {},
      selectedFiles: [],
      selectedFile: {},
      thumbnail: "",
      loading: false,
      cropped: "",
      previewFile: "",
      attachmentNote: "",
      showImgCroppedImage: true,
      options: {
        format: "png",
        circle: false,
        size: this.optionsSize,
      },
      mime: "",
      width: "",
      height: "",
      note: "",
      duration: "",
    };
  },
  computed: {
    getViewPort() {
      return { width: 383, height: 282, type: "square" };
    },
  },
  methods: {
    openCroppieArea() {
      this.showCroppieArea = true;
      this.showImgCroppedImage = false;
      this.$nextTick(() => {
        this.$refs.croppieRef.bind({
          url: this.previewFile,
        });
      });
    },
    async cropImage() {
      await this.$refs.croppieRef.result(this.options, (output) => {
        this.cropped = output;
        this.previewFile = output;
      });
      this.showCroppieArea = false;
      this.showImgCroppedImage = true;
    },
    async selectVideo(file) {
      const self = this;
      const vid = document.createElement("video");
      // const canvas = document.createElement("canvas");
      // vid.muted = true;
      vid.src = URL.createObjectURL(file.target.files[0]);
      vid.ondurationchange = function () {
        self.duration = this.duration;
      };
      vid.addEventListener("loadedmetadata", function () {
        self.height = this.videoHeight;
        self.width = this.videoWidth;
      });
      self.thumbnail = await this.generateVideoThumbnail(file.target.files[0]);
    },
    onFileSelect(file) {
      this.busy = true;
      let self = this;
      this.selectedFile = file.target.files[0];
      this.fileName = file.target.files[0].name;
      this.fileType = file.target.files[0].type;
      this.fileSize = file.target.files[0].size;
      // if (this.fileSize > 150000000) {
      //   this.notifyErrorMessage("File size is over 150 MB.");
      //   return;
      // }
      window.URL = window?.URL || window?.webkitURL;
      const inputFile =
        file.target.files ||
        file.dataTransfer.files ||
        this.$refs.inputField.files;
      const reader = new FileReader();
      this.previewFile = "";
      reader.readAsDataURL(file.target.files[0]);
      if (this.$refs.inputField.files.length >= 2) {
        this.selectedMultipleFiles(inputFile);
      } else if (
        ["mp4"].includes(file.target.files[0].name.split(".").pop()) ||
        this.fileType == "video/mp4"
      ) {
        this.selectVideo(file);
        reader.addEventListener("load", function () {
          self.imageCropie = true;
          self.busy = false;
          self.previewFile = reader.result;
        });
      } else {
        this.imageCropie = true;
        reader.onload = async (newFile) => {
          let image = new Image();
          image.src = newFile.target.result;
          image.onload = function () {
            self.width = this.width;
            self.height = this.height;
          };
          this.previewFile = newFile.target.result;
          this.thumbnail = await this.generateImageThumbnail(
            this.previewFile,
            390,
            200
          );
          this.thumbnail = await this.generateImageThumbnail(
            this.previewFile,
            200,
            200
          );
          this.busy = false;
        };
      }
    },
    selectedMultipleFiles(inputFile) {
      const self = this;
      inputFile.forEach(async (file) => {
        let reader = new FileReader();
        this.selectedFile = file;
        this.fileName = file.name;
        this.fileType = file.type;
        this.fileSize = file.size;
        reader.readAsDataURL(file);
        const payload = {
          id: Math.random().toString(36).substring(2),
          type: file.name.split(".").pop(),
          file: file,
          name: file.name,
          size: file.size,
          ext: file.name.split(".").pop(),
          note: "",
        };
        if (["mp4"].includes(file.name.split(".").pop())) {
          reader.addEventListener("load", () => {
            // this.previewFile = reader.result;
            this.$nextTick(async () => {
              const thumbnail = await this.generateVideoThumbnail(file);
              const vid = document.createElement("video");

              vid.src = URL.createObjectURL(file);
              vid.ondurationchange = function () {
                self.duration = this.duration;
                // let video = document.getElementById('my-video');

                vid.addEventListener("loadedmetadata", function () {
                  self.height = this.videoHeight;
                  self.width = this.videoWidth;

                  self.selectedFiles.push({
                    ...payload,
                    src: reader.result,
                    thumb: thumbnail,
                    thumbFile: self.dataURLtoBlob(thumbnail),
                  });
                  self.$emit("file-upload", {
                    ...payload,
                    src: reader.result,
                    height: self.height,
                    width: self.width,
                    thumb: thumbnail,
                    thumbFile: self.dataURLtoBlob(thumbnail),
                    duration: self.duration,
                    note: "",
                  });
                });
              };
            });
          });
        } else {
          const self = this;
          reader.onload = async (theFile) => {
            let image = new Image();
            image.src = theFile.target.result;

            image.onload = async function () {
              // access image size
              self.width = this.width;
              self.height = this.height;
              let thumbnail = await self.generateImageThumbnail(
                reader.result,
                390,
                200
              );
              self.selectedFiles.push({
                ...payload,
                src: reader.result,
                width: self.width,
                height: self.height,
                thumb: thumbnail,
                thumbFile: self.dataURLtoBlob(thumbnail),
              });
              self.$emit("file-upload", {
                ...payload,
                src: reader.result,
                width: self.width,
                height: self.height,
                thumb: thumbnail,
                thumbFile: self.dataURLtoBlob(thumbnail),
                note: "",
              });
            };
          };
        }
      });
      this.$nextTick(() => {
        this.busy = false;
      });
    },
    pickFile() {
      this.$refs.inputField.click();
    },
    processDraggedFile(e) {
      const inputFile =
        e.target.files || e.dataTransfer.files || this.$refs.inputField.files;

      if (inputFile.length >= 2) {
        this.selectedMultipleFiles(inputFile);
      } else {
        let reader = new FileReader();
        this.selectedFile = inputFile[0];
        this.fileName = inputFile[0].name;
        this.fileType = inputFile[0].type;
        this.fileSize = inputFile[0].size;
        reader.readAsDataURL(inputFile[0]);

        if (["mp4"].includes(inputFile[0]?.name?.split(".").pop())) {
          const self = this;
          const vid = document.createElement("video");
          vid.src = URL.createObjectURL(inputFile[0]);
          vid.ondurationchange = function () {
            self.duration = this.duration;
          };
          vid.addEventListener("loadedmetadata", function () {
            self.height = this.videoHeight;
            self.width = this.videoWidth;
          });
          reader.addEventListener("load", async () => {
            self.previewFile = reader.result;
            self.thumbnail = await self.generateVideoThumbnail(inputFile[0]);
            self.imageCropie = true;
          });
        } else {
          this.imageCropie = true;
          reader.onload = async () => {
            this.thumbnail = await this.generateImageThumbnail(
              reader.result,
              390,
              390
            );
            this.width = this.$refs.attachment.$el.clientWidth;
            this.height = this.$refs.attachment.$el.clientHeight;
            this.previewFile = reader.result;
            // this.$refs.croppieRef.bind({
            //   url: reader.result,
            // });
          };
        }
      }
    },
    selectFileForUpload() {
      if (this.loading) return;
      this.loading = true;
      if (this.actionFile && this.actionFile.id) {
        // const payload = {
        //   id: "new-id" + this.selectedFile.size,
        //   type: this.actionFile.name.split(".").pop(),
        //   file: this.actionFile,
        //   name: this.actionFile.name,
        //   size: this.actionFile.size,
        //   src: this.previewFile,
        //   ext: this.actionFile.name.split(".").pop(),
        //   note: this.attachmentNote,
        // };
        this.selectedFiles = this.selectedFiles.map((file) => {
          if (file.id === this.actionFile.id) {
            file.note = this.attachmentNote;
            file.src = this.previewFile;
          }
          return file;
        });
        this.$emit("file-update", {
          id: Math.random().toString(36).substring(2),
          type: this.actionFile.name.split(".").pop(),
          file: this.dataURLtoBlob(this.actionFile.src),
          name: this.actionFile.name,
          size: this.actionFile.size,
          src: this.previewFile,
          ext: this.actionFile.name.split(".").pop(),
          mime: this.selectedFile.type,
          note: this.attachmentNote,
          width: this.width,
          height: this.height,
          duration: this.selectedFile.type === "video/mp4" ? this.duration : "",
        });
        this.attachmentNote = "";
        this.actionFile = {};
      } else {
        let payload = {
          id: Math.random().toString(36).substring(2),
          type: this.selectedFile.name.split(".").pop(),
          file: this.dataURLtoBlob(this.previewFile),
          name: this.selectedFile.name,
          size: this.selectedFile.size,
          src: this.previewFile,
          thumb: this.thumbnail || this.previewFile,
          thumbFile: this.dataURLtoBlob(this.thumbnail || this.previewFile),
          ext: this.selectedFile.name.split(".").pop(),
          mime: this.selectedFile.type,
          note: this.attachmentNote,
          width: this.width,
          height: this.height,
          duration: this.selectedFile.type === "video/mp4" ? this.duration : "",
        };
        this.selectedFiles.push(payload);
        this.$emit("file-upload", payload);
        this.attachmentNote = "";
      }
      this.loading = false;

      this.$nextTick(() => {
        this.cancelImageUpload();
      });
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0] ? arr[0].match(/:(.*?);/)[1] : "";
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const blob = new Blob([u8arr], { type: mime });
      blob.name = this.fileName;
      return blob;
    },
    removeSelectedFile() {
      this.selectedFiles = this.selectedFiles.filter(
        (attachment) => attachment.id !== this.actionFile.id
      );
      this.$emit("remove-file", this.actionFile.id);
      this.actionFile = {};
      this.removeModal = false;
    },
    cancelImageUpload() {
      this.imageCropie = false;
      this.busy = false;
      this.showImgCroppedImage = false;
      this.selectedFile = "";
      this.attachmentNote = "";
      this.previewFile = "";
      this.fileName = "";
      this.fileType = "";
      this.fileSize = "";
      this.actionFile = {};
    },
    openDeleteDialog(file) {
      this.actionFile = file;
      this.removeModal = true;
    },
    openEditModal(file) {
      this.actionFile = file;
      this.fileType = this.actionFile.type;
      this.showImgCroppedImage = true;
      this.showCroppieArea = false;
      // this.$refs.croppieRef.bind({
      //   url: file.src,
      // });
      this.previewFile = file.src;
      this.attachmentNote = file.note;
      this.imageCropie = true;
    },
    croppedResults(output) {
      this.previewFile = output;
    },
    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          return resolve(canvas.toDataURL("image/png"));
        };
      });
    },
    generateImageThumbnail(base64, newWidth, newHeight) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        canvas.style.width = newWidth.toString() + "px";
        canvas.style.height = newHeight.toString() + "px";
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
          context.scale(newWidth / img.width, newHeight / img.height);
          context.drawImage(img, 0, 0);
          resolve(canvas.toDataURL());
        };
      });
    },
  },
};
</script>

<style scoped>
.cardBackgroud {
  background-color: #e7e7e7;
}

.dropzoneBackground {
  background-color: #f1f5fc;
}

.darkThemeBackground {
  background-color: #22272e;
  color: #f1f5fc;
  border: 0.5px solid #f1f5fc;
}
</style>