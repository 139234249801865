var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    ref: "drop",
    staticClass: "tag-input",
    attrs: {
      "items": _vm.searchItems,
      "item-value": "id",
      "dense": "",
      "filled": "",
      "outlined": "",
      "density": "comfortable",
      "placeholder": "Select a release",
      "item-text": "version",
      "append-icon": "mdi-chevron-down",
      "clearable": _vm.multiple,
      "loading": _vm.loading,
      "multiple": _vm.multiple,
      "deletable-chips": _vm.multiple,
      "small-chips": "",
      "disabled": _vm.disable,
      "return-object": "",
      "search-input": _vm.search,
      "hide-details": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "focusin": function focusin($event) {
        $event.preventDefault();
        return _vm.fetchData('no-default');
      },
      "change": function change($event) {
        return _vm.$emit('change');
      },
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pl-2 pr-5",
          attrs: {
            "align": "center",
            "no-gutters": ""
          }
        }, [_c('v-col', [_c('div', {
          staticClass: "caption"
        }, [_vm._v("Version")])]), _c('v-col', {
          staticClass: "caption"
        }, [_c('div', [_vm._v("Version Code")])]), _c('v-col', [_c('div', {
          staticClass: "caption text-right"
        }, [_vm._v("Released")])])], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-row', {
          attrs: {
            "align": "center",
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-list-item-title', {
          attrs: {
            "id": "data-item"
          }
        }, [_c('MaxText', {
          attrs: {
            "text": data.item.version,
            "max": 13
          }
        })], 1)], 1), _c('v-col', {
          staticClass: "caption"
        }, [_c('MaxText', {
          attrs: {
            "text": data.item.version_code,
            "max": 13
          }
        })], 1), _c('v-col', [_c('div', {
          staticClass: "caption text-right"
        }, [_c('vue-hoverable-date', {
          attrs: {
            "date": data.item.created_at
          }
        })], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.selectedData,
      callback: function callback($$v) {
        _vm.selectedData = $$v;
      },
      expression: "selectedData"
    }
  }, [_c('template', {
    slot: "selection"
  }, [_c('release-chip', {
    attrs: {
      "release": _vm.selectedData
    }
  })], 1), _c('template', {
    slot: "no-data"
  }, [_c('div', [_vm.loading ? _c('div', {
    staticClass: "d-flex align-center justify-center pa-4"
  }, [_c('ContentLoader', {
    attrs: {
      "size": "25"
    }
  })], 1) : _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.noDataMessage
    }
  })], 1)])])], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }