var render = function render(){
  var _vm$releaseInfo, _vm$releaseInfo$info, _vm$releaseInfo2, _vm$releaseInfo3, _vm$releaseInfo3$info, _vm$releaseInfo4, _vm$currentUser, _vm$currentUser2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-1",
    attrs: {
      "disabled": _vm.loading,
      "flat": ""
    }
  }, [_c('v-row', [!_vm.fromReleasePage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h2 bold-text"
  }, [_vm._v("Feedback")]), _c('p', [_vm._v("Share your feedback directly with the developers of this app.")])]) : _vm._e(), _vm.fromReleasePage && _vm.releaseInfo && _vm.releaseInfo.platform ? _c('v-col', {
    staticClass: "bodyFont pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('ul', [_c('li', [_vm._v(" Platform: " + _vm._s(_vm.releaseInfo.platform === "android" ? "Android" : "iOS") + " ")]), _c('li', [_vm._v(" Version: "), _c('MaxText', {
    attrs: {
      "text": "v".concat(((_vm$releaseInfo = _vm.releaseInfo) === null || _vm$releaseInfo === void 0 ? void 0 : (_vm$releaseInfo$info = _vm$releaseInfo.info) === null || _vm$releaseInfo$info === void 0 ? void 0 : _vm$releaseInfo$info.version) || ((_vm$releaseInfo2 = _vm.releaseInfo) === null || _vm$releaseInfo2 === void 0 ? void 0 : _vm$releaseInfo2.version), " (").concat(((_vm$releaseInfo3 = _vm.releaseInfo) === null || _vm$releaseInfo3 === void 0 ? void 0 : (_vm$releaseInfo3$info = _vm$releaseInfo3.info) === null || _vm$releaseInfo3$info === void 0 ? void 0 : _vm$releaseInfo3$info.version_code) || ((_vm$releaseInfo4 = _vm.releaseInfo) === null || _vm$releaseInfo4 === void 0 ? void 0 : _vm$releaseInfo4.version_code), ")"),
      "max": "40"
    }
  })], 1)])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [!_vm.fromInstallPage && !_vm.fromReleasePage && _vm.fromFeedbackPage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "regular-font subListFont mb-4 text-center"
  }, [_vm._v(" Select Platform ")]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center cursor-pointer",
    attrs: {
      "cols": "6",
      "md": "2",
      "sm": "6"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedPlatform === 'android' ? 'success' : '',
      "disabled": _vm.fromReleasePage && _vm.releaseInfo && _vm.releaseInfo.id && _vm.selectedPlatform !== 'android',
      "size": "50"
    },
    domProps: {
      "textContent": _vm._s('mdi-android')
    },
    on: {
      "click": function click($event) {
        _vm.selectedPlatform = 'android';
      }
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v("Android")])], 1), _c('v-col', {
    staticClass: "text-center cursor-pointer",
    attrs: {
      "cols": "6",
      "md": "2",
      "sm": "6"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedPlatform === 'ios' ? 'primary' : '',
      "disabled": _vm.fromReleasePage && _vm.releaseInfo && _vm.releaseInfo.id && _vm.selectedPlatform !== 'ios',
      "size": "50"
    },
    domProps: {
      "textContent": _vm._s('mdi-apple')
    },
    on: {
      "click": function click($event) {
        _vm.selectedPlatform = 'ios';
      }
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v("iOS")])], 1)], 1)], 1) : _vm._e(), !_vm.fromInstallPage && !_vm.fromReleasePage && _vm.fromFeedbackPage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.fromInstallPage ? _c('release-auto-complete', {
    key: _vm.selectedPlatform,
    staticClass: "mt-n4",
    attrs: {
      "app_id": _vm.$route.params.app_id,
      "platform": _vm.selectedPlatform
    },
    model: {
      value: _vm.selectedReleases,
      callback: function callback($$v) {
        _vm.selectedReleases = $$v;
      },
      expression: "selectedReleases"
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.fromReleasePage && _vm.fromInstallPage && !((_vm$currentUser = _vm.currentUser) !== null && _vm$currentUser !== void 0 && _vm$currentUser.id) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:20',
      expression: "'required|min:3|max:20'"
    }],
    attrs: {
      "filled": "",
      "label": "Your name",
      "placeholder": "What is your name?"
    },
    model: {
      value: _vm.formValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "name", $$v);
      },
      expression: "formValues.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', ''), false))], 1) : _vm._e(), !_vm.fromReleasePage && _vm.fromInstallPage && !((_vm$currentUser2 = _vm.currentUser) !== null && _vm$currentUser2 !== void 0 && _vm$currentUser2.id) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'email',
      expression: "'email'"
    }],
    attrs: {
      "filled": "",
      "hint": "if you require a response from the developers",
      "label": "Your email",
      "persistent-hint": "",
      "placeholder": "And your email? (Optional)"
    },
    model: {
      value: _vm.formValues.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "email", $$v);
      },
      expression: "formValues.email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', ''), false))], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "counter": "600",
      "filled": "",
      "hide-details": "auto",
      "label": "Message",
      "persistent-hint": "",
      "placeholder": "Do you have a comment for this release?",
      "rows": "4"
    },
    model: {
      value: _vm.formValues.message,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "message", $$v);
      },
      expression: "formValues.message"
    }
  }, 'v-textarea', _vm.veeValidate('Message', ''), false)), _vm.errorMessage ? _c('div', {
    staticClass: "error--text"
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1), !_vm.fromInstallPage && !_vm.shareUid ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('new-file-upload', {
    attrs: {
      "attachment-errors": _vm.attachmentErrors,
      "progress": _vm.loading,
      "progress-list": _vm.progressList
    },
    on: {
      "file-upload": _vm.addFileToUppy,
      "file-update": _vm.updateFile,
      "remove-file": _vm.removeSelectedFile
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-center mt-4"
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": _vm.messageSent ? 'success' : 'primary',
      "loading": _vm.loading,
      "outlined": _vm.$vuetify.theme.dark,
      "right": ""
    },
    on: {
      "click": _vm.sendReleaseFeedback
    }
  }, [!_vm.messageSent ? _c('div', [_vm._v("Post")]) : _c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Comment sent! ")], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }