var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Archive Release")])]), _c('v-card-text', {
    staticClass: "font-16"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b', [_vm._v("What happens when you archive?")]), _c('br'), _c('br'), _c('p', [_vm._v(" Archiving removes the app's files (.apk or .ipa), making them unavailable for download. However, you'll retain access to all the related details, like comments and install counts. ")]), _c('p', [_vm._v(" Depending on your team's plan, the auto-archive might be set by default. ")]), _c('p', [_vm._v(" Team auto-archive is set to: "), _c('b', [_vm._v(_vm._s(_vm.getTeamAutoArchive()))]), _vm._v(". ")]), _c('p', [_vm._v(" This app auto-archive is set to: "), _c('b', [_vm._v(_vm._s(_vm.getTeamPlanUsage.auto_archive_release ? _vm.getAutoArchiveValue() : _vm.getTeamAutoArchive()))]), _vm._v(". ")]), _c('b', [_vm._v("Ways to archive")]), _c('br'), _c('br'), _c('ul', [_c('li', [_c('b', [_vm._v("Do it yourself:")]), _vm._v(" Manually archive a release at any time. ")]), _c('li', [_c('b', [_vm._v("While releasing:")]), _vm._v(" Opt to archive the previous version when launching a new release. ")]), _c('li', [_c('b', [_vm._v("Set it and forget it:")]), _vm._v(" Configure auto-archive settings in the "), _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$router.push("/app/".concat(_vm.$route.params.app_id, "/settings/releases"));
      }
    }
  }, [_vm._v("app settings")]), _vm._v(" to automatically archive releases after a set period. ")])]), _c('br'), _c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "color": "success",
      "icon": "info",
      "outlined": ""
    }
  }, [_vm._v(" Archiving releases free up storage space, as archived files are not counted towards your storage quota. It's an effective way to manage your resources. ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }