<template>
  <v-card :disabled="loading" class="pa-1" flat>
    <v-row>
      <v-col v-if="!fromReleasePage" cols="12">
        <div class="font-size-h2 bold-text">Feedback</div>
        <p>Share your feedback directly with the developers of this app.</p>
      </v-col>
      <v-col
        v-if="fromReleasePage && releaseInfo && releaseInfo.platform"
        class="bodyFont pb-0"
        cols="12"
      >
        <ul>
          <li>
            Platform:
            {{ releaseInfo.platform === "android" ? "Android" : "iOS" }}
          </li>
          <li>
            Version:
            <MaxText
              :text="`v${releaseInfo?.info?.version || releaseInfo?.version} (${
                releaseInfo?.info?.version_code || releaseInfo?.version_code
              })`"
              max="40"
            />
          </li>
        </ul>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="!fromInstallPage && !fromReleasePage && fromFeedbackPage"
            cols="12"
          >
            <div class="regular-font subListFont mb-4 text-center">
              Select Platform
            </div>
            <v-row align="center" justify="center">
              <v-col class="text-center cursor-pointer" cols="6" md="2" sm="6">
                <v-icon
                  :color="selectedPlatform === 'android' ? 'success' : ''"
                  :disabled="
                    fromReleasePage &&
                    releaseInfo &&
                    releaseInfo.id &&
                    selectedPlatform !== 'android'
                  "
                  size="50"
                  @click="selectedPlatform = 'android'"
                  v-text="'mdi-android'"
                ></v-icon>
                <div class="mt-3">Android</div>
              </v-col>
              <v-col class="text-center cursor-pointer" cols="6" md="2" sm="6">
                <v-icon
                  :color="selectedPlatform === 'ios' ? 'primary' : ''"
                  :disabled="
                    fromReleasePage &&
                    releaseInfo &&
                    releaseInfo.id &&
                    selectedPlatform !== 'ios'
                  "
                  size="50"
                  @click="selectedPlatform = 'ios'"
                  v-text="'mdi-apple'"
                ></v-icon>
                <div class="mt-3">iOS</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="!fromInstallPage && !fromReleasePage && fromFeedbackPage"
            cols="12"
          >
            <release-auto-complete
              v-if="!fromInstallPage"
              :key="selectedPlatform"
              v-model="selectedReleases"
              :app_id="$route.params.app_id"
              :platform="selectedPlatform"
              class="mt-n4"
            ></release-auto-complete>
          </v-col>
          <v-col
            v-if="!fromReleasePage && fromInstallPage && !currentUser?.id"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="formValues.name"
              v-validate="'required|min:3|max:20'"
              filled
              label="Your name"
              placeholder="What is your name?"
              v-bind="veeValidate('Name', '')"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="!fromReleasePage && fromInstallPage && !currentUser?.id"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="formValues.email"
              v-validate="'email'"
              filled
              hint="if you require a response from the developers"
              label="Your email"
              persistent-hint
              placeholder="And your email? (Optional)"
              v-bind="veeValidate('Email', '')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="formValues.message"
              v-validate="'max:600'"
              counter="600"
              filled
              hide-details="auto"
              label="Message"
              persistent-hint
              placeholder="Do you have a comment for this release?"
              rows="4"
              v-bind="veeValidate('Message', '')"
            >
            </v-textarea>
            <div v-if="errorMessage" class="error--text">
              {{ errorMessage }}
            </div>
          </v-col>
          <v-col v-if="!fromInstallPage && !shareUid" cols="12">
            <new-file-upload
              :attachment-errors="attachmentErrors"
              :progress="loading"
              :progress-list="progressList"
              @file-upload="addFileToUppy"
              @file-update="updateFile"
              @remove-file="removeSelectedFile"
            ></new-file-upload>
          </v-col>
          <v-col class="text-center mt-4">
            <v-btn
              :color="messageSent ? 'success' : 'primary'"
              :loading="loading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              right
              @click="sendReleaseFeedback"
            >
              <div v-if="!messageSent">Post</div>
              <div v-else>
                <v-icon left>mdi-check</v-icon>
                Comment sent!
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  SEND_INSTALL_FEEDBACK,
  SEND_RELEASE_FEEDBACK,
} from "@/store/releases/releases.module";
import { isEmpty } from "@/core/services/helper.service";
import veeValidate from "@/mixins/veeValidate";
import { mapGetters, mapMutations } from "vuex";
// import LatestReleaseSelection from "@/view/components/Releases/LatestReleaseSelection";
import NewFileUpload from "@/view/components/Releases/NewFileUpload";
import * as tus from "tus-js-client";
import ReleaseAutoComplete from "@/view/components/Common/ReleaseAutoComplete";
// import StorageService from "@/core/services/localstorage.service";

export default {
  components: {
    ReleaseAutoComplete,
    NewFileUpload,
    // LatestReleaseSelection,
  },
  mixins: [veeValidate],
  props: {
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
    developerName: {
      type: String,
      default: "",
    },
    shareUid: {
      type: String,
      default: "",
    },
    fromInstallPage: {
      type: Boolean,
      default: false,
    },
    loadingFeedback: {
      type: Boolean,
      default: false,
    },
    fromFeedbackPage: {
      type: Boolean,
      default: false,
    },
    releaseInfo: {
      type: [Object, Boolean],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      messageSent: false,
      uppyConfig: "",
      selectedPlatform: "android",
      releaseList: [{ version: 1.22 }],
      feedbackAttachmentUpload: false,
      attachmentErrors: [],
      isEmpty,
      tab: 1,
      loading: false,
      feedbackLoadingCopy: this.loadingFeedback,
      uploadingProgress: 0,
      progressList: [],
      attachmentList: [],
      attachmentPayload: [],
      selectedReleases: {},
      errorMessage: null,
      formValues: {
        name: "",
        email: "",
        message: "",
        rating: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      isAuthenticated: "isAuthenticated",
      getTotalProgress: "getTotalProgress",
      getAttachmentsProgressList: "getAttachmentsProgressList",
    }),
    getTourInfo() {
      let info = JSON.parse(localStorage.getItem("tour"));
      if (info && info.upload_queue === "true") {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    getTotalProgress(val) {
      if (val >= this.getAttachmentsProgressList.length * 99) {
        // this.clearAttachmentsProgress();
        this.notifyUserMessage("Feedback sent.");
        this.loading = false;
        this.$emit("success");
      }
    },
  },
  created() {
    if (
      this.isAuthenticated &&
      (this.getMobileOperatingSystem !== "unknown" || this.fromReleasePage)
    ) {
      this.formValues.name = this.currentUser?.name;
      this.formValues.email = this.currentUser?.email;
    }
  },
  mounted() {
    if (this.fromReleasePage && this.releaseInfo?.id) {
      this.selectedPlatform = this.releaseInfo.platform;
      this.tab = 3;
    }
  },
  methods: {
    ...mapMutations({
      increaseReleaseFeedbackCount: "increaseReleaseFeedbackCount",
      setAttachmentsProgressList: "setAttachmentsProgressList",
      clearAttachmentsProgress: "clearAttachmentsProgress",
      setAttachmentsList: "setAttachmentsList",
      setFeedbackLoader: "setFeedbackLoader",
      setUploadQueueTour: "setUploadQueueTour",
      appCommentCount: "appCommentCount",
      updateFeedbackProgressList: "updateFeedbackProgressList",
      clearAttachmentList: "clearAttachmentList",
      updateAttachmentProgress: "updateAttachmentProgress",
      setAttachmentsListId: "setAttachmentsListId",
      clearFromFeedbackSocket: "clearFromFeedbackSocket",
      setAttachmentThumbnailList: "setAttachmentThumbnailList",
    }),
    addFileToUppy(event) {
      this.attachmentList.push({
        url: event.src,
        name: event.name,
        mime: event.mime,
        status: 1,
        progress: 0,
        uploadedSize: 0,
        uploadStatus: "uploading",
        thumb: event.thumb,
        extension: "." + event.name.split(".").pop(),
        big: event.src,
        id: event.id,
        file: event.file,
        image: event.src,
        appID: this.$route.params.app_id,
        releaseID: this.releaseInfo?.id || "",
        user: this.currentUser.name,
        thumbFile: event.thumbFile,
        ext: "." + event.name.split(".").pop(),
        note: event.note,
        size: event.size,
      });
      if (event.duration) {
        this.attachmentPayload.push({
          id: event.id,
          name: event.name,
          ext: "." + event.name.split(".").pop(),
          note: event.note,
          size: event.size,
          duration: event.duration,
          height: event.height,
          width: event.width,
          mime: event.file.type,
        });
      } else {
        this.attachmentPayload.push({
          id: event.id,
          name: event.name,
          ext: "." + event.name.split(".").pop(),
          note: event.note,
          size: event.size,
          height: event.height,
          width: event.width,
          mime: event.file.type,
        });
      }
    },
    removeSelectedFile(id) {
      this.attachmentList = this.attachmentList.filter(
        (attachment) => attachment.id !== id
      );
      this.attachmentPayload = this.attachmentList.filter(
        (attachment) => attachment.id !== id
      );
    },
    updateFile(payload) {
      this.attachmentList = this.attachmentList.map((file) => {
        if (file.id === payload.id) {
          file.note = payload.note;
          file.src = payload.src;
          file.file = payload.file;
        }
        return file;
      });
      this.attachmentPayload = this.attachmentPayload.map((file) => {
        if (file.id === payload.id) {
          file.note = payload.note;
          file.src = payload.src;
          file.file = payload.file;
        }
        return file;
      });
    },
    uploadThumbnail(attachment) {
      let self = this;
      let tusUpload = new tus.Upload(
        this.attachmentList.find((x) => x.id === attachment.file.id)?.thumbFile,
        {
          endpoint: attachment?.thumbnail,
          chunkSize: 50 * 1024 * 1024,
          // parallelUploads: 2,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          // overridePatchMethod: false,
          headers: {},
          metadata: {
            filename: attachment?.file?.name,
            filetype: attachment?.file?.mime,
          },
          onError: function (error) {
            self.logError(error);
            return false;
          },
          onProgress: function () {
            // let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
            // If the status is a 403, we do nNBnot want to retry.
            if (status === 403) {
              return false;
            }

            return true;
          },
          onSuccess: function () {
            return "success";
          },
        }
      );
      tusUpload.start();
    },
    uploadFile(attachment) {
      this.setFeedbackLoader(true);
      let self = this;
      let tusUpload = new tus.Upload(
        this.attachmentList.find((x) => x.id === attachment?.file?.id)?.file,
        {
          endpoint: attachment?.url,
          chunkSize: 50 * 1024 * 1024,
          // parallelUploads: 2,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          overridePatchMethod: false,
          headers: {},
          metadata: {
            filename: attachment?.file?.name,
            filetype: attachment?.file?.mime,
          },
          onError: function (error) {
            self.logError(error);
            let message = error.message
              ?.substring(
                error.message.lastIndexOf(":") + 2,
                error.message.lastIndexOf('"')
              )
              ?.trim();
            if (
              error &&
              error.message &&
              error.message.includes("x text: n/a")
            ) {
              message = "Check your internet connection and try again.";
            }
            self.notifyErrorMessage({
              message:
                message || "Something went wrong, please try again later",
            });
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
            self.updateFeedbackProgressList({
              id: attachment?.file?.id,
              uploadingSize: bytesUploaded,
              uploadStatus: "uploading",
              progress: percentage > 99 ? 99 : percentage,
            });
            if (status === 403) {
              return false;
            }

            // For any other status code, tus-js-client should retry.
            return true;
          },
          onSuccess: function () {
            self.updateFeedbackProgressList({
              id: attachment?.file?.id,
              uploadingSize: attachment?.file?.size,
              uploadedSize: attachment?.file?.size,
              uploadStatus: "uploaded",
              progress: 100,
            });
            self.setFeedbackLoader(false);
            return "success";
          },
        }
      );
      tusUpload.start();
    },
    async sendReleaseFeedback() {
      this.errorMessage = null;
      if (await this.validateAllFields()) {
        if (!this.formValues.message && !this.formValues.rating) {
          this.errorMessage =
            "Please provide your message or rating to submit your feedback";
          return;
        }
        if (this.releaseInfo?.id) {
          this.formValues.release_id = this.releaseInfo.id;
        }
        this.messageSent = false;
        if (this.loading) return;
        this.loading = true;
        this.feedbackLoadingCopy = true;
        const uid = this.$route.params.uid || this.shareUid;
        if (this.fromFeedbackPage || (this.fromReleasePage && !this.shareUid)) {
          this.sendInternalFeedback();
        } else if (uid) {
          this.$store
            .dispatch(SEND_INSTALL_FEEDBACK, {
              uid,
              ...this.formValues,
              type: this.selectedPlatform,
            })
            .then(() => {
              this.loading = false;
              this.messageSent = true;
              this.resetForm();
              this.$validator.errors.clear();
              this.$emit("close");
              setTimeout(() => {
                this.loading = false;
                this.messageSent = false;
                this.$validator.errors.clear();
              }, 3000);
            })
            .catch((err) => {
              this.loading = false;
              this.errorMessage = err.message;
            });
        }
      }
    },
    sendInternalFeedback() {
      if (!this.selectedReleases.id && !this.releaseInfo?.id) {
        this.loading = false;
        this.errorMessage = "Please select release to submit your feedback";
        return;
      }
      this.$store
        .dispatch(SEND_RELEASE_FEEDBACK, {
          app_id: this.$route.params.app_id,
          release_id:
            this.$route.params.release_id ??
            this.releaseInfo?.id ??
            this.selectedReleases?.id,
          message: this.formValues.message,
          rating: this.formValues.rating,
          attachments: this.attachmentPayload.map((item) => {
            let file = {
              file: item,
            };
            return file;
          }),
        })
        .then((response) => {
          this.increaseReleaseFeedbackCount({
            id: this.releaseInfo?.id ?? this.selectedReleases?.id,
          });
          this.appCommentCount();
          this.clearFromFeedbackSocket();
          if (response.attachments && response.attachments.length) {
            this.setAttachmentsList(this.attachmentList);
            this.setAttachmentThumbnailList(this.attachmentList);
            if (this.getTourInfo) {
              this.setUploadQueueTour(true);
            }
            // StorageService.setItem('feedbackList', this.attachmentList)
            response.attachments.forEach((attachment) => {
              this.setAttachmentsListId(response.id);
              this.uploadThumbnail(attachment);
              this.uploadFile(attachment);
              this.notifyUserMessage({ message: response.message });
              this.$emit(
                "success",
                this.releaseInfo?.id ?? this.selectedReleases?.id
              );
              this.loading = false;
            });
          } else {
            this.notifyUserMessage({ message: response.message });
            this.$emit(
              "success",
              this.releaseInfo?.id ?? this.selectedReleases?.id
            );
            this.loading = false;
          }
          this.$emit("close");
          this.loading = false;
          this.feedbackLoadingCopy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.attachmentErrors = err.attachments || [];
          this.loading = false;
        });
    },
    resetForm() {
      this.formValues = {
        name: "",
        email: "",
        message: "",
        rating: 0,
      };
      setTimeout(() => {
        this.$validator.errors.clear();
      }, 500);
    },
  },
};
</script>

<style scoped>
.selectionArea {
  border-radius: 18px;
  background-color: #f0f0f0;
}

.scrollbar {
  height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
}
</style>
