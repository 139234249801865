var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-card",
    class: {
      'table-card--clickable': _vm.clickableRow
    }
  }, [_vm.title ? _c('v-card-title', [_c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _c('v-card', {
    attrs: {
      "outlined": "",
      "flat": "",
      "rounded": ""
    }
  }, [_vm.loading ? _c('div', [_c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : [_vm._t("default")], _vm.pagination && _vm.pageCount > 1 ? _c('v-card-actions', {
    staticClass: "table-card__actions"
  }, [_c('v-pagination', {
    attrs: {
      "disabled": _vm.loading,
      "length": _vm.pageCount,
      "total-visible": 7
    },
    on: {
      "input": _vm.onChange
    },
    model: {
      value: _vm.pagination.page,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "page", $$v);
      },
      expression: "pagination.page"
    }
  })], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }