var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.busy ? _c('v-overlay', {
    attrs: {
      "value": true,
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1) : _vm._e(), _vm.selectedFiles.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm._l(_vm.selectedFiles, function (file, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-hover', {
      attrs: {
        "close-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('v-card', {
            staticClass: "cardBackgroud",
            attrs: {
              "outlined": ""
            }
          }, [_c('v-row', [_c('v-col', {
            attrs: {
              "cols": "12",
              "md": "12"
            }
          }, [_c('v-img', {
            ref: "attachment",
            refInFor: true,
            attrs: {
              "src": file.thumb || file.src,
              "contain": "",
              "height": "200",
              "width": "100%"
            }
          }, [_c('span', {
            staticClass: "top float-right"
          }, [_c('v-icon', {
            attrs: {
              "color": "primary"
            },
            domProps: {
              "textContent": _vm._s(file.mime === 'video/mp4' ? 'mdi-message-video' : 'mdi-image')
            }
          })], 1)]), _c('v-overlay', {
            attrs: {
              "value": hover,
              "absolute": "",
              "color": "grey",
              "opacity": "0.1"
            }
          }, [_c('div', {
            staticClass: "center-el"
          }, [_c('v-btn', {
            staticClass: "mr-1",
            attrs: {
              "color": "white",
              "fab": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openEditModal(file);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "primary"
            }
          }, [_vm._v("mdi-square-edit-outline")])], 1), _c('v-btn', {
            attrs: {
              "color": "white",
              "fab": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openDeleteDialog(file);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "primary"
            }
          }, [_vm._v("mdi-delete")])], 1)], 1)])], 1), _vm.attachmentErrors.length ? _c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_vm.attachmentErrors.length ? _c('div', {
            staticClass: "px-2 error--text"
          }, [_vm._v(" " + _vm._s(_vm.attachmentErrors.find(function (x) {
            return x.info.id == file.id;
          }).message) + " ")]) : _vm._e()]) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('v-col', {
    staticClass: "text-center drag-upload",
    attrs: {
      "cols": "12",
      "md": "4"
    },
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.processDraggedFile.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    key: _vm.imageCropie,
    ref: "inputField",
    staticClass: "input-file",
    attrs: {
      "id": "file-upload",
      "accept": '.jpg,.png,.mp4',
      "disabled": _vm.busy,
      "multiple": "",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileSelect($event);
      }
    }
  }), _c('v-card', {
    staticClass: "center-el",
    attrs: {
      "height": "200",
      "outlined": ""
    },
    on: {
      "click": _vm.pickFile
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "tile": "",
      "x-small": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "grey-muted-text bodyFont pointer"
  }, [_c('div', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" Upload a file from your system "), _c('span', {
    staticClass: "primary--text text-decoration-underline cursor-pointer"
  }, [_vm._v("Browser")])])])])], 1)], 1)], 1)])], 2)], 1) : _vm._e(), !_vm.selectedFiles.length ? _c('v-col', {
    staticClass: "drag-upload center-el pa-0 text-center",
    attrs: {
      "cols": "12"
    },
    on: {
      "click": _vm.pickFile
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "color": _vm.$vuetify.theme.dark ? '' : '#f1f5fc',
      "flat": !_vm.$vuetify.theme.dark,
      "outlined": _vm.$vuetify.theme.dark,
      "width": "100%"
    }
  }, [_c('div', {
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.processDraggedFile.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    key: _vm.imageCropie,
    ref: "inputField",
    staticClass: "input-file",
    attrs: {
      "id": "file-upload",
      "accept": '.jpg,.png,.mp4',
      "disabled": _vm.busy,
      "multiple": "",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileSelect($event);
      }
    }
  }), _c('div', {
    staticClass: "titleFont mb-4 primary--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    },
    domProps: {
      "textContent": _vm._s('mdi-paperclip')
    }
  }), _vm._v(" No attachments ")], 1), _c('div', {
    staticClass: "pointer"
  }, [_vm._v(" Drag and drop "), _c('div', [_vm._v(" or upload a file from your system "), _c('span', {
    staticClass: "primary--text text-decoration-underline cursor-pointer"
  }, [_vm._v("Browser")])])])])])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": "canvas"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$actionFile;
        return [_c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "primary--text font17"
        }, [_vm._v("Attachment")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.cancelImageUpload
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.imageCropie ? _c('v-card-text', {}, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm.previewFile && ['video/mp4', 'mp4'].includes(_vm.fileType) ? _c('video', {
          ref: "attachment",
          attrs: {
            "id": "my-video",
            "controls": "",
            "height": "200",
            "width": "100%"
          }
        }, [_c('source', {
          attrs: {
            "src": _vm.previewFile,
            "type": "video/mp4"
          }
        })]) : _c('div', [!_vm.showImgCroppedImage && _vm.showCroppieArea ? _c('vue-croppie', {
          ref: "croppieRef",
          attrs: {
            "boundary": _vm.getViewPort,
            "enableResize": true,
            "viewport": _vm.getViewPort,
            "enforceBoundary": ""
          },
          on: {
            "result": _vm.croppedResults
          }
        }) : _vm._e(), _vm.showImgCroppedImage || !_vm.showCroppieArea ? _c('v-img', {
          ref: "attachment",
          attrs: {
            "src": _vm.previewFile,
            "contain": "",
            "height": "282",
            "width": "100%"
          }
        }) : _vm._e(), !_vm.showImgCroppedImage && _vm.showCroppieArea ? _c('div', {
          staticClass: "text-center"
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.cropImage
          }
        }, [_vm._v(" Crop ")])], 1) : _vm._e()], 1)]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-textarea', _vm._b({
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'max:100',
            expression: "'max:100'"
          }],
          attrs: {
            "counter": "100",
            "filled": "",
            "outlined": "",
            "placeholder": "Notes",
            "rows": "2"
          },
          model: {
            value: _vm.attachmentNote,
            callback: function callback($$v) {
              _vm.attachmentNote = $$v;
            },
            expression: "attachmentNote"
          }
        }, 'v-textarea', _vm.veeValidate('Note', ''), false))], 1)], 1)], 1) : _vm._e(), _c('v-card-actions', {
          staticClass: "mb-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.showCroppieArea,
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.selectFileForUpload
          }
        }, [_vm._v(" " + _vm._s((_vm$actionFile = _vm.actionFile) !== null && _vm$actionFile !== void 0 && _vm$actionFile.id ? "Update" : "Add") + " ")]), _c('v-spacer')], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.imageCropie,
      callback: function callback($$v) {
        _vm.imageCropie = $$v;
      },
      expression: "imageCropie"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Remove app")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to remove this file?")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppCancelBtn",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.removeModal = false;
            }
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppConfirmBtn",
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.removeSelectedFile
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.removeModal,
      callback: function callback($$v) {
        _vm.removeModal = $$v;
      },
      expression: "removeModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }