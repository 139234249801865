<template>
  <v-row>
    <v-col>
      <v-divider></v-divider>
      <DatatableWrapper
        :pagination="pagination"
        :loading="releaseLoading"
        clickable-row
      >
        <v-data-table
          v-model="selectedItem"
          :headers="headers"
          :items="releases"
          hide-default-footer
          mobile-breakpoint="0"
          hide-default-header
          @page-count="pageCount = $event"
          @click:row="navigateToDetailPage($event.id)"
          @toggle-select-all="selectAllToggle"
        >
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  :class="`text-${header.align}`"
                  v-for="header in headers"
                  :key="header.text"
                >
                  <span v-if="header.value !== 'release'">
                    {{ header.text }}
                  </span>
                  <v-btn
                    @click="showAutoArchiveLearnMore = true"
                    v-if="header.value === 'archive'"
                    icon
                  >
                    <v-icon small v-text="'mdi-help-circle-outline'"></v-icon>
                  </v-btn>
                  <v-btn
                    @click="showReleaseStatusLearnMore = true"
                    v-if="header.value === 'status'"
                    icon
                  >
                    <v-icon small v-text="'mdi-help-circle-outline'"></v-icon>
                  </v-btn>
                  <v-row align="center">
                    <v-col
                      v-if="header.value === 'release' && canManageRelease"
                      cols="auto"
                    >
                      <v-checkbox v-model="selectAll"></v-checkbox
                    ></v-col>
                    <v-col
                      v-if="!selectedItem.length && header.value === 'release'"
                      cols="auto"
                    >
                      Release
                    </v-col>
                    <v-col
                      v-if="selectedItem.length && header.value === 'release'"
                      cols="auto"
                    >
                      <v-btn
                        v-if="selectedItem.length"
                        color="warning"
                        class="text-transform-none mr-2"
                        small
                        @click="openActionConfirmation('archive')"
                      >
                        <v-icon left small>archive </v-icon>
                        Archive (<b>{{ selectedItem.length }}</b
                        >)
                      </v-btn>
                      <v-btn
                        v-if="selectedItem.length"
                        color="error"
                        class="text-transform-none"
                        small
                        @click="openActionConfirmation('delete')"
                      >
                        <v-icon left small>cancel </v-icon>
                        Delete (<b>{{ selectedItem.length }}</b
                        >)
                      </v-btn>
                    </v-col>
                  </v-row>
                </th>
                <!-- Add your custom header here -->
              </tr>
            </thead>
          </template>

          <template #item.release="{ item }">
            <v-row class="cursor-pointer" align="center">
              <v-col class="mr-5" cols="auto">
                <v-simple-checkbox
                  v-if="canManageRelease"
                  :disabled="![1, 2, 3].includes(item?.status)"
                  :readonly="![1, 2, 3].includes(item?.status)"
                  :value="isSelected(item)"
                  class="mr-n14"
                  @input="select(item)"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="auto">
                <div>
                  <img
                    :src="
                      item.image ||
                      `/media/defaults/${
                        $vuetify.theme.dark
                          ? 'app_default_light'
                          : 'app_default'
                      }.svg`
                    "
                    height="40"
                    @error="replaceDefaultReleaseImage"
                  />
                </div>
              </v-col>
              <v-col>
                <release-chip
                  :release="{
                    ...item.info,
                    status: item?.status,
                    platform: item.platform,
                  }"
                  :version-length="11"
                ></release-chip>
                <div class="smallFont info--text" v-if="item?.meta?.latest">
                  Latest Release
                </div>
              </v-col>
            </v-row>
          </template>

          <template #item.size="{ item }">
            {{ formatBytes(item.info.size) }}
          </template>

          <template #item.developer="{ item }">
            <user-avatar :user-detail="item.user"></user-avatar>
          </template>

          <template #item.download="{ item }">
            <span v-if="canManageApp">
              <div
                @click.stop="
                  $router.push({
                    name: 'release-install',
                    params: {
                      app_id: $route.params.app_id,
                      release_id: item.id,
                    },
                  })
                "
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn color="primary" icon v-on="on">
                      <b>{{
                        numberWithCommas(
                          (item?.stats?.installs || 0) +
                            (item?.stats?.downloads || 0)
                        )
                      }}</b>
                    </v-btn>
                  </template>
                  <span>
                    <div>
                      Total downloads:
                      {{ numberWithCommas(item?.stats?.downloads || 0) }}
                    </div>
                    <div>
                      Total install:
                      {{ numberWithCommas(item?.stats?.installs || 0) }}
                    </div>
                    <div class="my-3">
                      We are updating this info every minute.
                    </div>
                  </span>
                </v-tooltip>
              </div>
            </span>
            <span v-else> - </span>
          </template>

          <template #item.comments="{ item }">
            <span v-if="canManageApp">
              <v-btn
                color="primary"
                icon
                @click.stop="
                  $router.push({
                    name: 'release-comment-list',
                    params: {
                      app_id: $route.params.app_id,
                      release_id: item.id,
                    },
                  })
                "
              >
                <b>
                  {{ numberWithCommas(item?.stats?.comments) }}
                </b>
              </v-btn>
            </span>
            <span v-else> - </span>
          </template>

          <template #item.archive="{ item }">
            <div v-if="item.archive_at">
              <vue-hoverable-date
                v-if="item.archive_at"
                :date="item.archive_at"
              ></vue-hoverable-date>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    v-text="'mdi-information-outline'"
                  ></v-icon>
                </template>
                <span
                  >{{ item?.meta?.archive_reason || "Auto archived" }}
                </span>
              </v-tooltip>
            </div>
            <div v-else>
              <vue-tooltip displayText="-" text="No expiry date"></vue-tooltip>
            </div>
          </template>

          <template #item.date="{ item }">
            <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
          </template>

          <template #item.status="{ item }">
            <div>
              <v-tooltip v-if="[4, 5].includes(item.status)" bottom>
                <template #activator="{ on }">
                  <v-progress-circular
                    :color="$options.STATUS[item?.status]?.color"
                    :size="15"
                    :width="2"
                    indeterminate
                    v-on="on"
                  ></v-progress-circular>
                </template>
                <span>
                  {{ $options.STATUS[item?.status]?.title }}
                </span>
              </v-tooltip>
              <vue-tooltip
                v-else
                :icon-color="$options.STATUS[item?.status]?.color"
                :text="$options.STATUS[item?.status]?.title"
                :icon-text="$options.STATUS[item?.status]?.icon"
              >
              </vue-tooltip>
            </div>
          </template>

          <template #item.action="{ item }">
            <action-dropdown>
              <template v-slot:list>
                <v-list-item @click="navigateToDetailPage(item.id)">
                  <v-icon small>mdi-eye</v-icon>
                  View release
                </v-list-item>
                <v-list-item
                  v-if="[1].includes(item?.status)"
                  @click="onReleaseFeedbackForm(item)"
                >
                  <v-icon small>mdi-comment</v-icon>
                  Post Comment
                </v-list-item>
                <v-list-item
                  v-if="canManageRelease && [2, 3].includes(item?.status)"
                  @click="onDeleteClick(item)"
                >
                  <v-icon small>cancel</v-icon>
                  Delete
                </v-list-item>
                <v-list-item
                  v-if="canManageRelease && [1].includes(item?.status)"
                  @click="onArchivedClicked(item, 'fromList')"
                >
                  <v-icon small>archive</v-icon>
                  Archive
                </v-list-item>
                <v-list-item
                  v-if="[1].includes(item?.status)"
                  @click="onDownloadClick(item)"
                >
                  <v-icon small>get_app</v-icon>
                  Download app file
                </v-list-item>

                <!--  -->
              </template>
            </action-dropdown>
          </template>
          <template #no-data>
            <div>
              <no-data
                align-class="text-left"
                btn-icon=""
                btn-text="New Releases"
                first-text="No Releases, Just Yet..."
                second-text="<ul><li><strong>1. Upload</strong>: Add your APK or IPA to notify team members and guests.</li><li><strong>2. Installation</strong>: The release can be installed via our app or the public install page.</li><li><strong>3. Automate</strong>: Streamline your process with <a href='https://help.testapp.io/topic/integrations/' target='_blank'>our integrations</a>. Automate release uploads, notifications, and more to save time and ensure consistency.</li></ul>"
              ></no-data>
            </div>
          </template>
        </v-data-table>
      </DatatableWrapper>
    </v-col>
    <Modal
      v-model="showReleaseCommentForm"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="800"
    >
      <template #card>
        <v-card v-if="showReleaseCommentForm">
          <v-card-title>
            <div>
              <div>Post Comment</div>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="showReleaseCommentForm = false">
              <v-icon v-text="'mdi-close'"></v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-6">
            <feedback-form
              :release-info="selectedRelease"
              from-release-page
              @close="showReleaseCommentForm = false"
            ></feedback-form>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="modal">
      <template #card>
        <v-card>
          <v-card-title class="font-weight-bold">Delete release </v-card-title>
          <v-divider />
          <v-card-text class="my-4 message">
            <p>
              Are you sure you want to
              {{ selectedItem.length > 1 ? " archive and" : "" }} delete
              {{
                selectedItem && !selectedItem.length
                  ? "this"
                  : "the selected (" + selectedItem.length + ")"
              }}
              {{ selectedItem.length > 1 ? " releases" : " release" }}?
            </p>
            <v-alert
              border="left"
              class="mt-3"
              color="info"
              icon="info"
              outlined
            >
              Archived releases are not available for installation and are not
              counted towards your storage quota. <br /><br />
              They are there for your future reference.
            </v-alert>
            <v-alert
              border="left"
              class="mt-3"
              color="error"
              icon="error"
              outlined
            >
              Deleted releases will be removed from: <br /><br />
              <ul>
                <li>Releases page</li>
                <li>Installs page</li>
                <li>Share page</li>
                <li>Total releases stats (app and team)</li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-3">
            <div>No undo for this action</div>
            <v-spacer />
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              @click="onCancel"
              >No
            </v-btn>
            <v-btn
              :loading="loading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              @click="onConfirm"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="archiveModal" warning>
      <template #card>
        <template v-if="archiveModal">
          <v-card class="my-n3">
            <v-card-title>
              <div class="darkGrey--text bold-text">Archive release</div>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-4 message">
              Are you sure you want to archive
              {{
                selectedItem && !selectedItem.length
                  ? "this release?"
                  : "these selected releases?"
              }}

              <v-alert
                border="left"
                class="mt-3"
                color="info"
                icon="info"
                outlined
              >
                By archiving releases, we will be deleting the (.apk / .ipa)
                <br /><br />
                <div v-if="archiveReadmore">
                  Those releases will not be visible or available for
                  installation in our app and public install links.<br /><br />

                  Keeping all the data attached to these releases such as notes,
                  installs, feedback,... for your future reference.
                </div>
                <b
                  v-if="!archiveReadmore"
                  class="pointer"
                  @click="archiveReadmore = !archiveReadmore"
                >
                  {{ "Read more..." }}
                </b>
              </v-alert>
              <v-alert
                border="left"
                class="mt-3"
                color="success"
                icon="info"
                outlined
              >
                Archived releases will not be counted towards your team's
                storage quota 👍
              </v-alert>
            </v-card-text>
            <v-divider />
            <v-card-text class="pt-3">
              <v-row align="center">
                <v-col
                  :cols="!getTeamPlanUsage?.auto_archive_release ? 10 : 12"
                  class="py-2"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="noValue"
                        :disabled="!getTeamPlanUsage?.auto_archive_release"
                        :value="0"
                        hide-details
                        label="Now"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center">
                        <v-col>
                          <v-text-field
                            v-model="selectedDay"
                            :disabled="
                              noValue || !getTeamPlanUsage.auto_archive_release
                            "
                            filled
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="selectedTime"
                            :disabled="
                              noValue || !getTeamPlanUsage.auto_archive_release
                            "
                            :items="['Days', 'Months']"
                            filled
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="!getTeamPlanUsage.auto_archive_release"
                          cols="auto"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <v-btn icon @click="showStartTrialModal = true">
                                  <v-icon
                                    class="cursor-pointer"
                                    color="primary"
                                    v-text="'mdi-lock-outline'"
                                  >
                                  </v-icon>
                                </v-btn>
                              </span>
                            </template>
                            <span
                              >Auto archive settings is available for Starter &
                              Pro team plans</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col class="py-0" cols="12">
                          Note: Maximum 90 days or 3 months
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div>
                    You can change this setting for future releases from the
                    <span
                      class="primary--text cursor-pointer"
                      @click="
                        $router.push(
                          `/app/${$route.params.app_id}/settings/releases`
                        )
                      "
                      >app settings</span
                    >
                    page
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-3">
              <v-spacer />
              <v-btn
                class="mr-2 text-transform-none"
                color="primary"
                text
                @click="onCancelArchive"
                >No
              </v-btn>
              <v-btn
                :loading="loading"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                @click="onConfirmArchive"
                >Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </template>
    </Modal>
    <Modal closeable v-model="showAutoArchiveLearnMore">
      <template #card>
        <auto-archive-information></auto-archive-information>
      </template>
    </Modal>
    <Modal closeable v-model="showReleaseStatusLearnMore">
      <template #card>
        <v-card>
          <v-card-title>
            <div>Status</div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(status, index) in $options?.STATUSDESC"
                :key="index"
                class="bodyFont"
                cols="12"
              >
                <div>
                  <v-icon
                    :color="status.color"
                    left
                    v-text="
                      $options.STATUS[status?.value].icon ||
                      'mdi-radiobox-marked'
                    "
                  ></v-icon>
                  <span class="semi-bold"> {{ status.title }} </span>
                </div>
                <div class="ml-8">
                  {{ status.desc }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import {
  formatBytes,
  get,
  isEmpty,
  numberWithCommas,
} from "@/core/services/helper.service";
import { releaseStatus } from "@/consts/statuses";
import { mapGetters, mapMutations } from "vuex";
import {
  ARCHIVE_RELEASE_SCHEDULE,
  DELETE_RELEASE,
  UPDATE_BULK_RELEASES,
} from "@/store/releases/releases.module";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
import FeedbackForm from "@/view/components/Releases/FeedbackForm";
import { toNumber } from "@/core/utilities";
import UserAvatar from "@/view/components/Common/UserAvatar";
import AutoArchiveInformation from "@/view/components/Releases/AutoArchiveInformation";

export default {
  components: {
    UserAvatar,
    FeedbackForm,
    DatatableWrapper,
    AutoArchiveInformation,
  },
  STATUS: releaseStatus,
  STATUSDESC: [
    {
      title: "Active",
      color: "green",
      icon: "",
      value: 1,
      desc: "Release is active and ready to be installed.",
    },
    {
      title: "Failed",
      color: "red",
      value: 2,
      desc: "Upload failed. The release will be automatically deleted.",
    },
    {
      title: "Archived",
      color: "grey",
      value: 3,
      desc: "Release archived. Will not be available for install or download and the size will be removed from the team storage.",
    },
    {
      title: "Uploading",
      color: "orange",
      value: 4,
      desc: "Release file is being uploaded to our server. If you refreshed your page or connection lost, select the same file again and it will resume the upload in a new release.",
    },
    {
      title: "Processing",
      color: "info",
      value: 5,
      desc: "Release file is successfully uploaded and being processed to be available for install and download.",
    },
  ],
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      numberWithCommas,
      modal: false,
      fromList: false,
      loading: false,
      noValue: true,
      archiveModal: false,
      archiveReadmore: false,
      selectedRelease: {},
      selectedDay: 0,
      selectAll: false,
      selectedTime: "Days",
      showReleaseCommentForm: false,
      showReleaseStatusLearnMore: false,
      showAutoArchiveLearnMore: false,
      pagination: {
        page: this.toNumber(this.$route.query.page),
        limit: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
      totalReleasesCount: 0,
      selectedItem: [],
      releaseLoading: false,
      actionOption: [
        {
          value: "hide-action",
          title: "Select action",
        },
        {
          value: "archive",
          title: "Archive releases",
        },
        {
          value: "delete",
          title: "Delete releases",
        },
      ],
      selectedAction: {
        value: "hide-action",
        title: "Select action",
      },
    };
  },
  computed: {
    ...mapGetters({
      getAppInfo: "getAppInfo",
      releases: "getReleases",
      user: "currentUser",
      getReleasesMeta: "getReleasesMeta",
      getRefreshRelease: "getRefreshRelease",
      getTeamPlanUsage: "getTeamPlanUsage",
      getInfoErrorMessage: "getInfoErrorMessage",
      isSocketDisconnected: "getIsSocketDisconnected",
      getDataFromSocket: "getDataFromSocket",
    }),
    releaseHeaders() {
      return [
        {
          text: "Release",
          align: "left",
          sortable: false,
          permissions: true,
          width: "350",
          value: "release",
        },
        {
          text: "Size",
          align: "center",
          sortable: false,
          permissions: true,
          width: "100",
          value: "size",
        },
        {
          text: "Developer",
          align: "center",
          sortable: false,
          permissions: true,
          width: "170",
          value: "developer",
        },
        {
          text: "Installs",
          align: "center",
          sortable: false,
          width: 80,
          permissions: this.canManageRelease,
          value: "download",
        },
        {
          text: "Comments",
          align: "center",
          permissions: this.canManageRelease,
          sortable: false,
          width: 80,
          value: "comments",
        },
        {
          text: "Auto Archive",
          align: "center",
          sortable: false,
          permissions: this.canManageRelease,
          width: "120",
          value: "archive",
        },
        {
          text: "Created",
          align: "center",
          sortable: false,
          permissions: true,
          width: "120",
          value: "date",
        },
        {
          text: "Status",
          align: "center",
          permissions: true,
          sortable: false,
          value: "status",
          width: 0,
        },
        {
          text: "Action",
          align: "end",
          permissions: true,
          sortable: false,
          value: "action",
          width: 0,
        },
      ];
    },
    headers() {
      return this.releaseHeaders.filter((header) => !!header.permissions);
    },
    stats() {
      return [
        {
          title: "Android",
          icon: "mdi-android",
          color: "success",
          count: "5",
        },
        {
          title: "iOS",
          icon: "mdi-apple",
          color: "grey",
          count: "7",
        },
        {
          title: "Comments",
          icon: "mdi-comment-alert",
          color: "info",
          count: this.getAppInfo?.stats?.total_release_comments,
        },
        {
          title: "Installs",
          icon: "mdi-cellphone-arrow-down-variant",
          color: "info",
          count: this.getAppInfo?.stats?.total_installs,
        },
        {
          title: "Storage",
          storage: this.getAppInfo.storage,
          count: formatBytes(
            (this.getAppInfo?.storage?.usage?.releases || 0) +
              (this.getAppInfo?.storage?.usage?.attachments?.comments || 0) +
              (this.getAppInfo?.storage?.usage?.attachments?.feedback || 0)
          ),
        },
      ];
    },
    breadcrumbsItem() {
      return [
        {
          title: `${this.getAppInfo.name}`,
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          title: "Releases",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
      ];
    },
  },
  watch: {
    getRefreshRelease(val) {
      if (val) {
        this.getAllReleases();
        this.setReleaseRefresh(false);
      }
    },
    selectAll(val) {
      if (val) {
        this.selectedItem = this.releases.filter((item) =>
          [1, 2, 3].includes(item?.status)
        );
      } else {
        this.selectedItem = [];
      }
    },
    selectedDay(val) {
      if (val < 0) {
        this.selectedDay = 0;
      }
      if (this.selectedTime === "Months" && val > 3) {
        this.selectedDay = 3;
      }
      if (val > 90 && this.selectedTime === "Days") {
        this.selectedDay = 90;
      }
    },
    selectedTime(val) {
      if (this.selectedDay > 3 && val === "Months") {
        this.selectedDay = 3;
      }
      if (this.selectedDay > 90 && val === "Days") {
        this.selectedDay = 90;
      }
    },
    getDataFromSocket(val) {
      if (val) {
        if (val.app_id == this.$route.params.app_id) {
          this.setReleaseData(val);
        }
      }
    },
    getReleasesMeta(val) {
      this.pagination.totalItems = val.total;
    },
    totalReleasesCount(val) {
      this.pagination.totalItems = val;
    },
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getAllReleases({ page: newPage });
    },
    selectedAction(val) {
      if (val.value == "hide-action") {
        this.selectedItem = [];
      }
    },
  },
  created() {
    if (!this.getInfoErrorMessage) {
      this.getAllReleases();
    }
  },
  methods: {
    ...mapMutations({
      setDataFromSocket: "setDataFromSocket",
      removeDeletedRelease: "removeDeletedRelease",
      setReleaseInformation: "setReleaseInformation",
      changeReleaseToArchive: "changeReleaseToArchive",
      updateReleaseStatus: "updateReleaseStatus",
      removeReleaseInfo: "removeReleaseInfo",
      setReleaseRefresh: "setReleaseRefresh",
      updateReleaseInfo: "updateReleaseInfo",
      deductStorageFromAppInfo: "deductStorageFromAppInfo",
    }),

    getAllReleases({ page = this.pagination.page } = {}) {
      if (this.releaseLoading) return;
      this.releaseLoading = true;
      const params = {
        page,
      };
      this.$coreApi
        .get(
          `${this.apiRoutes.core.releases.list(
            this.$route.params.app_id
          )}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          this.setReleaseInformation({
            releases: data.data,
            releaseMeta: data.meta,
          });
        })
        .finally(() => {
          this.releaseLoading = false;
        });
    },
    openInstall(release) {
      this.selectedRelease = release;
      this.installLink = `${location.origin}/mobile-app/check?team_id=${this.getTeamId}&app_id=${this.$route.params.app_id}&release_id=${release?.id}`;
      this.installModal = true;
    },
    select(item) {
      let selectedIds = this.selectedItem.map((item) => item.id);
      if (selectedIds.includes(item.id)) {
        this.selectedItem = this.selectedItem.filter((i) => i.id !== item.id);
      } else {
        this.selectedItem.push(item);
      }
    },
    isSelected(item) {
      let selectedIds = this.selectedItem.map((item) => item.id);
      return selectedIds.includes(item.id);
    },
    openActionConfirmation(value) {
      this.selectedAction.value = value;
      if (value == "delete") {
        this.modal = true;
      } else {
        this.archiveModal = true;
      }
    },
    setReleaseData(data) {
      if (data?.status === 0) {
        this.removeDeletedRelease({ release_id: data.id, socket: true });
      } else if (data?.status === 2) {
        this.getAllReleases();
        this.showErrorMessage = true;
      } else {
        this.updateReleaseStatus(data);
      }
      this.$nextTick(() => {
        this.setDataFromSocket(null);
      });
    },
    selectAllToggle() {
      if (!this.selectedItem.length) {
        this.selectedItem = this.releases.filter((item) =>
          [1].includes(item?.status)
        );
      } else if (this.selectedItem.length !== this.releases.length) {
        this.selectedItem = this.releases.filter((item) =>
          [1].includes(item?.status)
        );
      } else {
        this.selectedItem = [];
      }
    },
    showInfo(event) {
      this.message = event;
      this.alert = true;
    },
    handlePrevReleasesPageChange(value) {
      this.pagination.page = value;
    },
    replaceDefaultReleaseImage(e) {
      e.target.src = `/media/defaults/${
        this.$vuetify.theme.dark ? "app_default_light" : "app_default"
      }.svg`;
    },
    onArchivedClicked(release, fromList) {
      this.selectedRelease = release;
      this.archiveModal = true;
    },
    onDownloadClick(release) {
      if (release.platform == "ios") {
        window.open(
          release?.url?.replace(new RegExp("plist"), "ipa") +
            "?user_id=" +
            this.user?.id,
          "_self"
        );
      } else {
        window.open(release.url + "?user_id=" + this.user?.id, "_self");
      }
    },
    navigateToDetailPage(releaseId) {
      this.$router.push({
        name: "release-detail-page",
        params: {
          app_id: this.$route.params.app_id,
          release_id: releaseId,
        },
      });
    },
    onDeleteClick(release) {
      this.selectedRelease = release;
      if (release?.status !== 2) {
        this.modal = true;
      } else {
        this.onConfirm();
      }
    },
    onCancel() {
      this.modal = false;
      this.fromList = false;
      this.selectedRelease = null;
    },
    onCancelArchive() {
      this.archiveModal = false;
      this.selectedDay = 0;
      this.selectedRelease = null;
    },
    onReleaseFeedbackForm(item) {
      this.selectedRelease = item;
      this.showReleaseCommentForm = true;
    },
    reconnectToSocket() {
      if (this.isSocketDisconnected) {
        this.connectToSocket();
      }
    },
    performSelectedAction() {
      if (this.loading) return;
      this.loading = true;
      const dataToPost = {
        app_id: this.$route.params.app_id,
        ids: this.selectedItem.map((item) => item.id),
        action: this.selectedAction.value,
      };
      this.$store
        .dispatch(UPDATE_BULK_RELEASES, dataToPost)
        .then((response) => {
          this.loading = false;
          this.archiveModal = false;
          this.modal = false;
          this.selectedAction = {
            value: "hide-action",
            title: "Select action",
          };
          this.notifyUserMessage({ message: response.message });
          this.selectedItem = [];
          this.getAllReleases({ page: this.pagination.page });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    multipleReleaseArchiveSchedule() {
      if (this.loading) return;
      this.loading = true;
      let value = this.noValue ? 0 : this.selectedDay;
      if (this.selectedTime === "Months") {
        value = this.selectedDay * 30;
      }
      this.$store
        .dispatch(ARCHIVE_RELEASE_SCHEDULE, {
          id: this.$route.params.app_id,
          // release_id: this.selectedRelease.id,
          ids: this.selectedItem.map((item) => item.id),
          action: "add_scheduled_archive",
          method: "time",
          value: parseInt(value),
        })
        .then((response) => {
          this.archiveModal = false;
          this.modal = false;
          this.selectedDay = 0;
          this.selectedAction = {
            value: "hide-action",
            title: "Select action",
          };
          this.notifyUserMessage({ message: response.message });
          this.selectedItem = [];
          this.getAllReleases({ page: this.pagination.page });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    onConfirmArchive() {
      if (this.selectedItem.length) {
        this.multipleReleaseArchiveSchedule();
        this.performSelectedAction();
      } else {
        if (this.loading) return;
        this.loading = true;
        let value = this.noValue ? 0 : this.selectedDay;
        if (this.selectedTime === "Months") {
          value = this.selectedDay * 30;
        }
        this.$store
          .dispatch(ARCHIVE_RELEASE_SCHEDULE, {
            id: this.getAppInfo.id,
            ids: [this.selectedRelease.id],
            // release_id: this.selectedRelease.id,
            action: "add_scheduled_archive",
            method: "time",
            value: parseInt(value),
          })
          .then((response) => {
            this.notifyUserMessage({
              message: response.message || "Release archived successfully",
            });
            if (!this.selectedDay) {
              this.changeReleaseToArchive({
                releaseId: this.selectedRelease.id,
              });
              this.deductStorageFromAppInfo(this.selectedRelease?.info?.size);
            } else {
              let totalDays =
                this.selectedTime === "Months"
                  ? this.selectedDay * 30
                  : this.selectedDay;
              let currentTimestamp = Date.now() / 1000;
              let extraTime = totalDays * 24 * 60 * 60;
              this.changeReleaseToArchive({
                releaseId: this.selectedRelease.id,
                archiveAt: currentTimestamp + extraTime,
              });
            }
            this.archiveModal = false;
            this.loading = false;
            this.selectedDay = 0;
            this.fromList = false;
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message || "Something went wrong");
            this.loading = false;
            this.fromList = false;
          });
      }
    },
    async onConfirm() {
      if (this.selectedItem.length) {
        this.performSelectedAction();
      } else {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(DELETE_RELEASE, {
            app_id: this.getAppInfo.id,
            release_id: this.selectedRelease.id,
          })
          .then((response) => {
            this.removeDeletedRelease({ release_id: this.selectedRelease.id });
            this.notifyUserMessage({
              message: response.message || `Release successfully deleted`,
            });

            this.removeReleaseInfo({
              count: 1,
              platform: this.selectedRelease.platform,
              size:
                this.selectedRelease?.status === 2
                  ? this.selectedRelease.info.size
                  : null,
            });
            this.loading = false;
            this.modal = false;
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message || "Delete release failed");
          });
      }
    },
    onEditClick(release) {
      this.selectedRelease = release;
      this.viewEditRelease = true;
    },
  },
};
</script>

<style scoped></style>
