<template>
  <v-card>
    <v-card-title>
      <div>Archive Release</div>
    </v-card-title>
    <v-card-text class="font-16">
      <v-row>
        <v-col cols="12">
          <b>What happens when you archive?</b>
          <br />
          <br />
          <p>
            Archiving removes the app's files (.apk or .ipa), making them
            unavailable for download. However, you'll retain access to all the
            related details, like comments and install counts.
          </p>

          <p>
            Depending on your team's plan, the auto-archive might be set by
            default.
          </p>

          <p>
            Team auto-archive is set to:
            <b>{{ getTeamAutoArchive() }}</b
            >.
          </p>

          <p>
            This app auto-archive is set to:
            <b>{{
              getTeamPlanUsage.auto_archive_release
                ? getAutoArchiveValue()
                : getTeamAutoArchive()
            }}</b
            >.
          </p>

          <b>Ways to archive</b>
          <br />
          <br />
          <ul>
            <li>
              <b>Do it yourself:</b> Manually archive a release at any time.
            </li>
            <li>
              <b>While releasing:</b> Opt to archive the previous version when
              launching a new release.
            </li>
            <li>
              <b>Set it and forget it:</b> Configure auto-archive settings in
              the
              <span
                class="primary--text cursor-pointer"
                @click="
                  $router.push(`/app/${$route.params.app_id}/settings/releases`)
                "
                >app settings</span
              >
              to automatically archive releases after a set period.
            </li>
          </ul>
          <br />
          <v-alert
            border="left"
            class="mt-3"
            color="success"
            icon="info"
            outlined
          >
            Archiving releases free up storage space, as archived files are not
            counted towards your storage quota. It's an effective way to manage
            your resources.
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      getAppInfo: "getAppInfo",
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
  },
  methods: {
    getAutoArchiveValue() {
      const value = this.getAppInfo?.settings?.auto_archive_release
        ? this.getAppInfo?.settings?.auto_archive_release / 86400
        : 0;
      if (value > 30 && value % 30 === 0) {
        return value / 30 + " Months";
      } else if (value) {
        return value + " Days";
      } else {
        return "Never";
      }
    },
    getTeamAutoArchive() {
      const value = this.getTeamPlanUsage?.release_expire
        ? this.getTeamPlanUsage?.release_expire / 86400
        : 0;
      if (value > 30 && value % 30 === 0) {
        return value / 30 + " Months";
      } else if (value) {
        return value + " Days";
      } else {
        return "Never";
      }
    },
  },
};
</script>
