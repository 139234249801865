<template>
  <div
    :class="{
      'table-card--clickable': clickableRow,
    }"
    class="table-card"
  >
    <v-card-title v-if="title"
      ><span class="darkGrey--text">{{ title }}</span></v-card-title
    >
    <v-card outlined flat rounded>
      <div v-if="loading">
        <v-row>
          <v-col>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <template v-else>
        <slot></slot>
      </template>

      <v-card-actions
        v-if="pagination && pageCount > 1"
        class="table-card__actions"
      >
        <v-pagination
          v-model="pagination.page"
          :disabled="loading"
          :length="pageCount"
          :total-visible="7"
          @input="onChange"
        ></v-pagination>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    pagination: {
      type: Object,
      required: false,
    },
    clickableRow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(
        this.pagination?.totalItems / this.pagination?.itemsPerPage
      );
    },
  },
  methods: {
    onChange(newPage) {
      this.page = newPage;
      this.$emit("onChange", this.page);
    },
  },
};
</script>

<style scoped>
.table-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05)) !important;
}

.table-card--clickable /deep/ tbody tr:hover {
  cursor: pointer;
}

.table-card__actions {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.v-pagination > li:first-child,
.v-pagination > li:last-child {
  display: none !important;
}
</style>
